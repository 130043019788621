import React, {useEffect, useRef, useState} from "react";
// import './index.css'
import {ProCard, ProFormList, ProFormSelect, ProList} from "@ant-design/pro-components";
import {Button, FloatButton, Form, Input, Select, Tag, Typography, Upload, Image, DatePicker, Spin} from "antd";
import {
    EditOutlined,
    EllipsisOutlined, FileAddOutlined, FormOutlined,
    LikeOutlined,
    MessageOutlined, PlusOutlined,
    SettingOutlined,
    StarOutlined
} from "@ant-design/icons";
import {cardPlayerGraph, getNewsDetail, newsOption, saveNews} from "../../config/api";
import {useLocation, useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import {useRequest} from "ahooks";
// import ImgCrop from 'antd-img-crop';

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const NewsList = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [imageList, setImageList] = useState([]);
    const [options, setOptions] = useState([[], []]);
    const [newsDetail, setNewsDetail] = useState({});
    const [form] = Form.useForm();

    const {runAsync: doGetNewsDetail, loading: getLoading} = useRequest(getNewsDetail, {manual: true});
    const {runAsync: doSaveNews, loading: saveLoading} = useRequest(saveNews, {manual: true});

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    useEffect(() => {
        let queryMessage = location.search.slice(1)
        if (queryMessage !== "") {
            console.log(queryMessage)
            doGetNewsDetail({value: queryMessage}).then(r => {
                r.dateTime =  dayjs(r.dateTime).add(8, "hour")
                setImageList(r.images.map(i => {
                    return {
                        status: 'done',
                        response: i,
                        url: i
                    }
                }))
                form.setFieldsValue(r)
                setNewsDetail(r)
            })
        }
        newsOption().then(r => {
            console.log(r)
            setOptions(r)
        })
    }, [])

    const save = (e) => {
        console.log(e)
        let news = {...newsDetail, ...e}
        let images = []
        imageList.map((item) => {
            if (item.status === "done") {
                images.push(item.response)
            }
        })
        news.images = images
        console.log(news)
        doSaveNews(news).then(r => {
            console.log(r)
            navigate("/news/list")
        })
    }

    const deleteNews = () => {
        let a = newsDetail
        a.status = 2
        setNewsDetail(a)
        setTimeout(() => form.submit(), 200)
    }

    return (
        <>
            <Spin spinning={getLoading || saveLoading}>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <div style={{display: "flex", flexDirection: "column", gap: 20, maxWidth: 500, width: "100%"}}>
                        <div style={{display: "flex", alignItems: "center", gap: 20}}>
                            <div style={{flex: 1, fontSize: 20}}></div>
                            <div><Button onClick={() => navigate("/news/list")}>返回</Button></div>
                            <div><Button danger onClick={deleteNews}>删除</Button></div>
                        </div>
                        <Form onFinish={save} form={form}
                            // initialValues={{
                            //     dateTime: dayjs(new Date()),
                            //     tags: "NBA"
                            // }}
                        >
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 10,
                                backgroundColor: "#fff",
                                padding: 20,
                                borderRadius: 10
                            }}>
                                <div>
                                    <div style={{marginBottom: 5}}>新闻标题</div>
                                    <Form.Item name="title" noStyle>
                                        <Input placeholder="请输入标题"/>
                                    </Form.Item>
                                </div>
                                
                                <div>
                                    <div style={{marginBottom: 5}}>新闻正文</div>
                                    
                                    {/*    <div>段落1</div>*/}
                                    {/*    <div style={{padding: "5px 0"}}>*/}
                                    {/*        <div style={{flex: 1}}>*/}
                                    {/*            <Input.TextArea value="文本段111111111111"></Input.TextArea>*/}
                                    {/*        </div>*/}
                                    {/*        <div style={{marginTop: 5}}>*/}
                                    {/*            <img style={{width: "100%"}} src="https://library.sportingnews.com/styles/crop_style_16_9_desktop_webp/s3/2024-03/nba-plain--50a2c2ba-49ff-4d55-bd57-0c3db9461e6f.jpeg.webp" alt=""/>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*    <div>段落2</div>*/}
                                    {/*    <div style={{padding: "5px 0"}}>*/}
                                    {/*        <div style={{flex: 1}}>*/}
                                    {/*            <Input.TextArea value="文本段2222"></Input.TextArea>*/}
                                    {/*        </div>*/}
                                    {/*        <div style={{marginTop: 5}}>*/}
                                    {/*            <img style={{width: "100%"}} src="https://library.sportingnews.com/styles/crop_style_16_9_desktop_webp/s3/2024-03/nba-plain--50a2c2ba-49ff-4d55-bd57-0c3db9461e6f.jpeg.webp" alt=""/>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*<div style={{textAlign: "center", border: "1px dotted #aaa", padding: 20, borderRadius: 10}}>添加文本段</div>*/}
                                    <Form.Item name="content" noStyle>
                                        <Input.TextArea style={{height: 200}} placeholder="请输入正文"></Input.TextArea>
                                    </Form.Item>
                                
                                </div>
                                
                                <div>
                                    <div style={{marginBottom: 5}}>新闻图片</div>
                                    <Form.Item name="images" noStyle>
                                        {/*<ImgCrop aspect={4/3} rotationSlider={true} quality={1} showGrid={true}>*/}
                                        <Upload
                                            action="https://aiviz.cn/api/aiviz/playground/upload"
                                            onChange={(e) => setImageList(e.fileList)}
                                            fileList={imageList}
                                            listType="picture-card"
                                            onPreview={handlePreview}
                                        >
                                            {/*{imageList.length >= 8 ? null :*/}
                                            <button style={{border: 0, background: 'none'}} type="button">
                                                <PlusOutlined/>
                                                <div style={{marginTop: 8,}}>Upload</div>
                                            </button>
                                            {/*}*/}
                                        </Upload>
                                        {/*</ImgCrop>*/}
                                    </Form.Item>
                                </div>
                                <div>
                                    <div style={{marginBottom: 5}}>新闻时间</div>
                                    <Form.Item name="dateTime" noStyle>
                                        <DatePicker style={{width:"100%"}} allowClear={false}/>
                                    </Form.Item>
                                </div>
                                <div>
                                    <div style={{marginBottom: 5}}>新闻标签</div>
                                    
                                    <Form.Item name="tags" noStyle>
                                        <Select
                                            placeholder="请选择标签"
                                            mode="multiple"
                                            style={{
                                                width: '100%',
                                            }}
                                            options={[
                                                {
                                                    value: 'NBA',
                                                    label: 'NBA',
                                                },
                                                {
                                                    value: 'NFL',
                                                    label: 'NFL',
                                                },
                                                {
                                                    value: 'Soccer',
                                                    label: 'Soccer',
                                                },
                                                {
                                                    value: '其他',
                                                    label: '其他',
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                </div>
                                
                                <div>
                                    <div style={{marginBottom: 5}}>关联球队</div>
                                    <Form.Item name="relevantTeam" noStyle>
                                        <Select
                                            mode="tags"
                                            placeholder="请选择球队"
                                            style={{
                                                width: '100%',
                                            }}
                                            options={options[1].map(i => {return {value: i, label: i,}})}
                                        />
                                    </Form.Item>
                                </div>
                                <div>
                                    <div style={{marginBottom: 5}}>关联球员</div>
                                    <Form.Item name="relevantPlayer" noStyle>
                                        <Select
                                            mode="tags"
                                            placeholder="请选择球员"
                                            style={{
                                                width: '100%',
                                            }}
                                            options={options[0].map(i => {return {value: i, label: i,}})}
                                        />
                                    </Form.Item>
                                </div>
                                <div>
                                    <div style={{marginBottom: 5}}>新闻链接</div>
                                    <Form.Item name="link" noStyle>
                                        <Input placeholder="请输入链接"/>
                                    </Form.Item>
                                </div>
                            </div>
                            <Button type="primary" block htmlType="submit" style={{marginTop: 20}}>保存</Button>
                        </Form>
                    </div>
                    {previewImage && (
                        <Image
                            wrapperStyle={{ display: 'none' }}
                            preview={{
                                visible: previewOpen,
                                onVisibleChange: (visible) => setPreviewOpen(visible),
                                afterOpenChange: (visible) => !visible && setPreviewImage(''),
                            }}
                            src={previewImage}
                        />
                    )}
                </div>
            </Spin>
        </>
    );
}

export default NewsList;
