import React, {useEffect, useState} from "react";
import {ProCard} from "@ant-design/pro-components";

function Home() {

    const [date, setDate] = useState(new Date())

    useEffect(() => {
        setInterval(() => {
            setDate(new Date())
        }, 1000)
    }, [])

    return (
        <div className="page-container">
            <ProCard title="Hello World">
                {date.toLocaleString()}
            </ProCard>
            {/*<Button type="primary">Start</Button>*/}
        </div>
    );
}

export default Home;
