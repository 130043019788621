import React, {useContext, useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {PageContainer, ProConfigProvider, ProLayout} from "@ant-design/pro-components";
import {routes as reactRoutes} from "../../config/routes";
import {ConfigProvider, Switch} from "antd";
import {CheckOutlined, CloseOutlined, MoonOutlined, SunOutlined} from "@ant-design/icons";
import {DataContext} from "../../store/DataContext";
import zhCN from "antd/locale/zh_CN";
import {doCheck} from "../../config/request";
import {useDispatch} from "react-redux";
const Page = () => {

    const navigate = useNavigate();
    const { darkMode, setDarkMode } = useContext(DataContext);
    const [routes, setRoutes] = useState([])
    const dispatch = useDispatch();
    useEffect(() => {
        if (localStorage.getItem("X-Access-Token")) {
            doCheck(dispatch).then(r => {
                if (r) {
                    setRoutes(reactRoutes[2].children.map(item => {
                        return {
                            path: "/tools" + (item.index ? "" : "/" + item.path),
                            name: item.name,
                            icon: item.icon,
                            routes: item?.children?.map(item => {
                                return {
                                    path: item.path,
                                    name: item.name,
                                    icon: item.icon,
                                }
                            })
                        }
                    }))
                } else {
                    navigate("/login")
                }
            })
        } else {
            navigate("/login")
        }
    }, [dispatch, navigate])
    const onChangeMenu = (item)  => {
        navigate(item)
    }
    
    
    return (
        <ConfigProvider theme={{token: {colorPrimary: "#6eddff"}}} locale={zhCN}>
            <ProConfigProvider dark={darkMode}>
                <ProLayout
                    title="CardCool Tools"
                    logo="https://10kv.oss-cn-shanghai.aliyuncs.com/cardcool/logo/logo2.png"
                    route={{routes}}
                    onMenuHeaderClick={() => onChangeMenu( "/tools")}
                    menuProps={{
                        onClick: ({key}) => {
                            onChangeMenu(key)
                        }
                    }}
                >
                    <PageContainer
                        extra={[
                                <Switch
                                    checkedChildren={<MoonOutlined />}
                                    unCheckedChildren={<SunOutlined />}
                                    checked={darkMode}
                                    onChange={(e) => {
                                        setDarkMode(e)
                                        localStorage.setItem("toolsDarkMode", e)
                                    }}
                                />
                            ]}
                    >
                        <Outlet />
                    </PageContainer>
                </ProLayout>
            </ProConfigProvider>
        </ConfigProvider>
    );
}

export default Page;
