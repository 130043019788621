import React from "react";
import {ProCard} from "@ant-design/pro-components";

const page = () => {

    return (
        <div>
            <ProCard>CardTypeIndex</ProCard>
        </div>
    );
}

export default page;
