import React from "react";

import Layout from "../common/layout";
import Result404 from "../common/result/404"
import Login from "../common/user/login";

import Home from "../pages/home"

import PlayerIndex from "../pages/player/index"
import NewsIndex from "../pages/news/index"
import PlayerList from "../pages/player/list"

import ToolsLayout from "../pages/tools/layout"
import ToolsList from "../pages/tools/list"
import ToolsListResult from "../pages/tools/listResult"
import OpenPrize from "../pages/tools/openPrize"
import {
    CloudOutlined, CreditCardOutlined, EditOutlined,
    HomeOutlined, TableOutlined, UnorderedListOutlined, UserOutlined
} from "@ant-design/icons";

import NewsList from "../pages/news/NewsList";
import NewsEdit from "../pages/news/NewsEdit";
import CardTypeIndex from "../pages/cardType";
import CardType from "../pages/cardType/cardType";
import AssociatePlayer from "../pages/cardType/associatedPlayer";

import PreMarkLayout from "../pages/preMark/layout"
import Order from "../pages/preMark/order";
import Evaluate from "../pages/preMark/evaluate";

import ShopLayout from "../pages/shopBackground/layout"
import ShopOrder from "../pages/shopBackground/order";
import ShopEvaluate from "../pages/shopBackground/evaluate";
import Details from "../pages/shopBackground/details";
import UploadVideo from "../pages/preMark/uploadVideo";

const customRoute = [
    {
        index: true,
        path: "/",
        name: "Home",
        icon: <HomeOutlined/>,
        element: <Home/>,
    },
    {
        name: "球员管理",
        path: "/player",
        icon: <UserOutlined />,
        children: [
            {
                index: true,
                element: <PlayerIndex/>,
            },
            {
                path: "list",
                name: "球员列表",
                icon: <TableOutlined/>,
                element: <PlayerList/>,
            },
        ]
    },
    {
        name: "新闻管理",
        path: "/news",
        icon: <CloudOutlined />,
        children: [
            {
                index: true,
                element: <NewsIndex/>,
            },
            {
                path: "list",
                name: "新闻列表",
                icon: <TableOutlined/>,
                element: <NewsList/>,
            },
            {
                path: "edit",
                name: "新闻编辑",
                icon: <EditOutlined />,
                element: <NewsEdit/>,
            },
        ]
    },
    {
        name: "卡种管理",
        path: "/cardTypeList",
        icon: <UnorderedListOutlined />,
        children: [
            {
                index: true,
                element: <CardTypeIndex/>,
            },
            {
                path: "cardType",
                name: "卡种列表",
                icon: <CreditCardOutlined />,
                element: <CardType/>,
            },
            {
                path: "associatePlayer",
                name: "关联球员",
                icon: <UserOutlined />,
                element: <AssociatePlayer/>,
            }
        ]
    },
    // {
    //     name: "Table",
    //     path: "/table",
    //     icon: <TableOutlined />,
    //     children: [
    //         {
    //             index: true,
    //             element: <ProCard>table index</ProCard>,
    //         },
    //         {
    //             path: "t1",
    //             name: "table1",
    //             icon: <TableOutlined/>,
    //             element: <ProCard>table1</ProCard>,
    //         },
    //         {
    //             path: "t2",
    //             name: "table2",
    //             icon: <TableOutlined/>,
    //             element: <ProCard>table2</ProCard>,
    //         },
    //     ]
    // },
]

const toolsRoute = [
    {
        path: "",
        name: "List Random",
        icon: <HomeOutlined/>,
        element: <ToolsList/>,
    },
    {
        path: "listResult",
        name: "List Random Result",
        icon: <HomeOutlined/>,
        element: <ToolsListResult/>,
    },
    {
        path: "upload",
        name: "upload",
        icon: <HomeOutlined/>,
        element: <UploadVideo/>,
    },
    {
        path: "openPrize",
        name: "开奖工具",
        icon: <HomeOutlined/>,
        element: <OpenPrize/>,
    },
    // {
    //     path: "v2",
    //     name: "test2",
    //     icon: <HomeOutlined/>,
    //     element: <div>v2</div>,
    // },
]

export const routes = [
    {
        path: "/",
        element: <Layout/>,
        children: [
            ...customRoute,
            {
                path: "*",
                element: <Result404/>,
            }]
    },
    {
        path: "/login",
        element: <Login/>,
    },
    
    {
        path: "/tools",
        element: <ToolsLayout/>,
        children: [
            ...toolsRoute,
            {
                path: "*",
                element: <Result404/>,
            }]
    },
    {
        path: "/preMark",
        element: <PreMarkLayout/>,
        children: [
            {
                path: "",
                name: "预评订单",
                icon: <HomeOutlined/>,
                element: <Order/>,
            },
            {
                path: "evaluate",
                name: "评价",
                icon: <HomeOutlined/>,
                element: <Evaluate/>,
            },
            {
                path: "*",
                element: <Result404/>,
            }]
    },
    {
        path: "/shopBackground",
        element: <ShopLayout/>,
        children: [
            {
                path: "",
                name: "商铺详情",
                icon: <HomeOutlined/>,
                element: <Details/>,
            },
            {
                path: "order",
                name: "订单",
                icon: <HomeOutlined/>,
                element: <ShopOrder/>,
            },
            {
                path: "evaluate",
                name: "评价",
                icon: <HomeOutlined/>,
                element: <ShopEvaluate/>,
            },
            {
                path: "*",
                element: <Result404/>,
            }]
    },
    
    {
        path: "*",
        element: <Result404/>,
    },
];
