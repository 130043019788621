import {Button, Checkbox, Form, InputNumber, Upload} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useContext, useState} from "react";
import {DataContext} from "../../store/DataContext";

const UploadVideo = () => {
    const {darkMode} = useContext(DataContext);
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    const onFinish = (values) => {
        console.log(values)
    }
    return <div style={{display: "flex",flexDirection: "column",gap: "10px"}}>
        <Form
            labelCol={{
                span: 4,
            }}
            wrapperCol={{
                span: 14,
            }}
            layout="horizontal"
            style={{
                maxWidth: 600,
            }}
            onFinish={onFinish}
        >
            <Form.Item name="homeImage" label="文件上传" valuePropName="fileList" getValueFromEvent={normFile}>
                <Upload action="https://aiviz.cn/api/aiviz/playground/upload" listType="picture-card" maxCount={1}>
                    <button
                        style={{
                            border: 0,
                            background: 'none',
                        }}
                        type="button"
                    >
                        <PlusOutlined style={{color: darkMode ? "white" : "black"}}/>
                        <div
                            style={{
                                marginTop: 8,
                                color: darkMode ? "white" : "black"
                            }}
                        >
                            上传
                        </div>
                    </button>
                </Upload>
            </Form.Item>
            <Form.Item style={{display: "flex",justifyContent: "flex-end"}} layout="horizontal">
                <Button type="primary" htmlType="submit">
                    提交
                </Button>
            </Form.Item>
        </Form>
    </div>
}
export default UploadVideo