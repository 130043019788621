import React, {useEffect, useRef, useState} from "react";
import {ProTable} from "@ant-design/pro-components";
import {getInfoFromEspn, getInfoFromEspnAll, getPlayerList, savePlayer} from "../../config/api";
import {Button, Form, Input, message, Modal, Select} from "antd";
import {Loading3QuartersOutlined, LoadingOutlined, UserAddOutlined} from "@ant-design/icons";
import {useRequest} from "ahooks";

const Page = () => {
    const [data, setData] = useState([])
    const [data1, setData1] = useState([])
    const [sportType, setSportType] = useState(localStorage.getItem("playerListSportType") !== null ? localStorage.getItem("playerListSportType") : "NBA")
    const actionRef = useRef();
    const [addPlayerModal, setAddPlayerModalOpen] = useState(false);
    
    const {loading: getDataLoading, runAsync: runGetData} = useRequest(getPlayerList, {manual: true});
    const {loading: savePlayerLoading, runAsync: runSavePlayer} = useRequest(savePlayer, {manual: true});
    const {loading: getInfoFromEspnLoading, runAsync: runGetInfoFromEspn} = useRequest(getInfoFromEspn, {manual: true});
    const {loading: getInfoFromEspnAllLoading, runAsync: runGetInfoFromEspnAll} = useRequest(getInfoFromEspnAll, {manual: true});
    const [getInfoFromEspnIndex, setGetInfoFromEspnIndex] = useState("");
    
    
    useEffect(() => {
        requestTableData()
    }, [])
    
    const requestTableData = (select) => {
        runGetData({value: select ? select : sportType}).then(r => {
            console.log(r)
            setData(r.sort((a, b) => b.sortIndex - a.sortIndex))
            setData1(r.sort((a, b) => b.sortIndex - a.sortIndex))
        })
    }

    const onFinish = (values) => {
        let add = {...values,enable:true,index:true,sportType}
        console.log(add)
        //缺少id
        setData([...data, add])
        setAddPlayerModalOpen(false);
        runSavePlayer(add).then(r => {
            console.log(r)

            getPlayerList({value: sportType}).then(r => {
                console.log(r)
                setData(r.sort((a, b) => b.sortIndex - a.sortIndex))
                setData1(r.sort((a, b) => b.sortIndex - a.sortIndex))
            })
        })
    };
    const saveEdite = (key,row) => {
        const index = data.findIndex(item => item.id === key)
        let newData = [...data]
        newData[index] = row
        setData(newData)
        setData1(newData)
        ///接口更改
        // console.log(newData)
        console.log(row)
        savePlayer(row).then(r => {
            console.log(r)
        })
    }
    const deleteItem = (key,row) => {
        console.log(key,row)
        const index = data.findIndex(item => item.id === key)
        let newData = [...data]
        newData.splice(index,index + 1)
        setData(newData)
        setData1(newData)
        //接口删除
        savePlayer({...row, enable: false, index: false}).then(r => {
            console.log(r)
        })
    }
    const searchList = (values) => {
        let newData = [...data1]
        let keys = Object.keys(values)
        for (let key of keys) {
            if (values[key] !== ""){
                newData = newData.filter(item => {
                    return item[key]?.toLowerCase().includes(values[key].toLowerCase())
                })
            }
        }
        setData(newData)
    }
    const sportTypeOptions = [
        "NBA",
        "Soccer",
        "NFL",
        "MLB",
    ]
    
    const columns = [
        {
            title: '排序',
            dataIndex: 'sortIndex',
            width: 100,
            hideInSearch: true
        },
        {
            title: '关键词',
            dataIndex: 'queryKeys',
            width: 200,
            render:(text, record, _, action) => {
                return <div>{text}</div>
            }
        },

        {
            title: '名字',
            dataIndex: 'name',
            width: 200,
            copyable: true,
            ellipsis: true,
            fixed: 'left',
        },
        {
            title: '中文名',
            dataIndex: 'chineseName',
            width: 200,
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: '昵称',
            dataIndex: 'nickName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '队名',
            dataIndex: 'teamName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '新秀年',
            dataIndex: 'debutYear',
            width: 100,
            ellipsis: true,
        },
        {
            title: '位置',
            dataIndex: 'position',
            width: 100,
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: '编号',
            dataIndex: 'number',
            width: 100,
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: '球员图片',
            dataIndex: 'peopleImage',
            width: 200,
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: 'ESPN Id',
            dataIndex: 'espnId',
            width: 200,
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: 'ESPN链接',
            dataIndex: 'espnPeopleUrl',
            width: 200,
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: '身高',
            dataIndex: 'height',
            width: 120,
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: '体重',
            dataIndex: 'weight',
            width: 120,
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: '生日',
            dataIndex: 'birthday',
            width: 200,
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: '大学',
            dataIndex: 'college',
            ellipsis: true,
            width: 200,
            hideInSearch: true
        },
        {
            title: '出生地',
            dataIndex: 'birthplace',
            ellipsis: true,
            width: 200,
            hideInSearch: true
        },
        {
            title: '选秀信息',
            dataIndex: 'draft',
            ellipsis: true,
            width: 300,
            hideInSearch: true
        },
        {
            title: '操作',
            valueType: 'option',
            key: 'option',
            align: 'center',
            fixed: 'right',
            width: 125,
            render: (text, record, _, action) => {
                const index = data.findIndex(item => item.id === record.id)
                
                return [
                    <div
                        className="text-button"
                        onClick={() => {
                            action?.startEditable?.(record.id);
                        }}
                    >
                        编辑
                    </div>,
                    // <div
                    //     className="text-button"
                    //     onClick={() => {
                    //         if (record.name !== null) {
                    //             message.error("请勿重复抓取").then()
                    //             return
                    //         }
                    //
                    //         if (getInfoFromEspnLoading) {
                    //             message.info("正在同步中").then()
                    //             return
                    //         }
                    //
                    //         setGetInfoFromEspnIndex(record.id)
                    //         runGetInfoFromEspn(record).then(
                    //             r => {
                    //                 const index = data.findIndex(item => item.id === record.id)
                    //                 let newData = [...data]
                    //                 newData[index] = r
                    //                 setData(newData)
                    //                 setGetInfoFromEspnIndex(null)
                    //             }
                    //         )
                    //     }}
                    // >
                    //     {/*同步ESPN*/}
                    //     {getInfoFromEspnIndex === record.id ? <LoadingOutlined/> : "同步ESPN"}
                    // </div>,
                    (record.name !== null && record.name !== "") ? (
                        <div
                            className="text-button"
                            onClick={() => {
                                setGetInfoFromEspnIndex(record.id)
                                // setTimeout(() => {
                                    let newData = [...data]
                                    let newPlayer = {...record};
                                    ["teamShortName","teamName","teamImage","seasonData","position","peopleImage","number","name","espnId","draft","debutYear","college","birthday","height","weight", "birthplace"].map(item => {
                                        newPlayer[item] = null
                                    })
                                    newData[index] = newPlayer
                                    setData(newData)
                                    setGetInfoFromEspnIndex(null)
                                // }, 1000)
                            }}
                        >
                            {/*同步ESPN*/}
                            {getInfoFromEspnIndex === record.id ? <LoadingOutlined/> : "重置球员"}
                        </div>
                    ) : null,
                    // Zaccharie Risacher
                    // -	扎卡里·里萨谢	-	-	2024	-	-	-	5211175	https://www.espn.com/nba/player/_/id/5211175/zaccharie-risacher	2.06 m	95 kg	-	-	-	2024: Rd 1, Pk 1 (ATL)
                    (data[index].name === null || data[index].name === "") ? (
                        <div
                            className="text-button"
                            onClick={() => {
                                if (getInfoFromEspnLoading) {
                                    message.info("正在同步中").then()
                                    return
                                }
                                setGetInfoFromEspnIndex(record.id)
                                runGetInfoFromEspn(record).then(
                                    r => {
                                        let newData = [...data]
                                        newData[index] = r
                                        setData(newData)
                                        setGetInfoFromEspnIndex(null)
                                    }
                                )
                            }}
                        >
                            {/*同步ESPN*/}
                            {getInfoFromEspnIndex === record.id ? <LoadingOutlined/> : "同步ESPN"}
                        </div>
                    ) : null
                ]
            },
        },
    ];
    
    return (
        <div>
            <ProTable
                loading={getDataLoading || savePlayerLoading || getInfoFromEspnAllLoading}
                rowKey="id"
                headerTitle={
                    <>
                        <div style={{fontSize: 16}}>运动类型：</div>
                        <Select
                            style={{width: 200}}
                            value={sportType}
                            onChange={(e) => {
                                setSportType(e)
                                localStorage.setItem("playerListSportType", e)
                                requestTableData(e)
                            }}
                            options={sportTypeOptions.map(item => ({label: item, value: item}))}
                        />
                    </>
                }
                actionRef={actionRef}
                dataSource={data}
                columns={columns}
                options={{
                    reload: () => {
                        requestTableData()
                    }
                }}
               onSubmit={searchList}
                editable={{
                    type: 'multiple',
                    onSave: (key, row) => saveEdite(key, row),
                    onDelete: (key, row) => deleteItem(key, row)
                }}
                scroll={{x: 3500}}
                toolBarRender={() => [
                    <Button
                        key="button"
                        icon={<UserAddOutlined/>}
                        type="primary"
                        onClick={() => setAddPlayerModalOpen(true)}
                    >
                        添加球员
                    </Button>,
                    <Button
                        key="button"
                        loading={getInfoFromEspnAllLoading}
                        type="primary"
                        onClick={() => {
                            runGetInfoFromEspnAll({value: sportType}).then(r => {
                                setData(r.sort((a, b) => b.sortIndex - a.sortIndex))
                            })
                        }}
                    >
                        同步ESPN数据
                    </Button>
                ]}
            />
            <Modal title="添加球员" open={addPlayerModal} onCancel={() => setAddPlayerModalOpen(false)} footer={null}
                   destroyOnClose>
                <Form
                    name="basic"
                    labelCol={{span: 6}}
                    wrapperCol={{span: 18}}
                    // size="small"
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    {
                        columns.map((item, index) => {
                            if (["排序", "位置", "编号", "球员图片", "身高", "体重", "生日", "大学", "ESPN Id", "出生地", "选秀信息", '操作', "队名", "新秀年"].includes(item.title)) return null
                            return (
                                <>
                                    <Form.Item
                                        key={index}
                                        labelAlign="right"
                                        label={item.title}
                                        name={item.dataIndex}
                                        extra={item.title === "名字" ? "*如需通过ESPN连接抓取，请留空" : null}
                                        // rules={[{ required: true, message: 'Please input the captcha you got!' }]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </>
                            )
                        })
                    }
                    
                    <Form.Item
                        style={{flex: 1, display: "flex", justifyContent: "flex-end"}}
                    >
                        <div style={{display: "flex", gap: "20px"}}>
                            <Button htmlType="reset">
                                重置
                            </Button>
                            <Button type="primary" htmlType="submit">
                                添加
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default Page;
