import React, {useEffect, useState} from "react";
// import './index.css'
import {ProCard, ProList} from "@ant-design/pro-components";
import {Button, FloatButton, Spin, Tag, Typography} from "antd";
import {
    EditOutlined,
    EllipsisOutlined, FileAddOutlined, FormOutlined,
    LikeOutlined,
    MessageOutlined,
    SettingOutlined,
    StarOutlined
} from "@ant-design/icons";
import {listNews} from "../../config/api";
import {useNavigate} from "react-router-dom";
import {useRequest} from "ahooks";
const NewsList = () => {

    const [newsList, setNewsList] = useState([])
    const navigate = useNavigate();
    const {loading: getNewsLoading, runAsync: runGetNews} = useRequest(listNews, {manual: true});
    useEffect(() => {
        runGetNews().then(r => {
            setNewsList(r)
        })
    }, [])

    const IconText = ({icon, text}) => (
        <span>
    {React.createElement(icon, {style: {marginInlineEnd: 8}})}
            {text}
  </span>
    );

    return (
        <Spin spinning={getNewsLoading}>
            <div style={{minHeight: "100vh", background: "#f3f3f3"}}>
                <div style={{display: "flex", flexDirection: "column", gap: 20, padding: 20}}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <div style={{flex: 1, fontSize: 20}}>新闻列表</div>
                        <div><Button type="primary" onClick={() => navigate("/news/edit")}>添加新闻</Button></div>
                    </div>
                    {newsList.map(item => {
                        return (
                            // <ProCard style={{display: "flex", padding: 20, backgroundColor: "#1e1f22", gap: 20}}>
                            //     <div style={{width: 100}}>
                            //         <img src={item.image} style={{width: "100%"}}/>
                            //     </div>
                            //     <div style={{flex: 1}}>{item.content}</div>
                            // </ProCard>
                            <ProCard
                                key={item.id}
                                title={item.title}
                                bordered
                                actions={[
                                    // <SettingOutlined key="setting" />,
                                    <EditOutlined key="edit" onClick={() => navigate("/news/edit?" + item.id)}/>,
                                    // <EllipsisOutlined key="ellipsis" />,
                                ]}
                            >
                                <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                                    <div style={{display: "flex", flexWrap: "wrap", gap: 10}}>
                                        <div style={{flex: 1, minWidth: 200, maxWidth: 300}}>
                                            <img src={item?.images?.[0]} style={{width: "100%"}} alt=""/>
                                        </div>
                                        <div style={{flex: 1, minWidth: 200,}}>
                                            <div className="list-content">
                                                {item.content}
                                            </div>
                                        </div>
                                    </div>
                                    <div><Tag>{item?.tags?.[0]}</Tag></div>
                                    <div style={{display: "flex", color: "#aaa"}}>
                                        <div style={{flex: 1}}>{item.createDate}</div>
                                        <div>By:</div>
                                    </div>
                                </div>
                            </ProCard>
                        )
                    })}
                </div>
                <FloatButton icon={<FormOutlined/>} type="primary" style={{right: 20}}
                             onClick={() => navigate("/news/edit")}/>
            </div>
        </Spin>
    );
}

export default NewsList;
