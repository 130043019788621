 import {request} from "./request";

export const getPlayerList = async body => request("admin/player/list", body);
export const savePlayer = async body => request("admin/player/save", body);
export const getInfoFromEspn = async body => request("admin/player/getInfoFromEspn", body);
export const getInfoFromEspnAll = async body => request("admin/player/getInfoFromEspn/all", body);
// export const getCardTypeList = async body => request("admin/cardType/list", body);
export const getSportTypeList = async body => request("static/sportType", body);

export const getAllTicket = async body => request("active/getAllTickets", body);
export const getOpenPhase = async body => request("active/getOpenPhase", body);
export const openPrize = async body => request("active/getAllTickets/open", body);
export const getPhase = async body => request("active/getPhase", body);




export const getOrderList = async body => request("preMark/admin/order", body);
export const getOrderById = async body => request("preMark/admin/getOrder", body);
export const getShopOrder = async body => request("preMark/admin/getShopOrder", body);
export const saveOrder = async body => request("preMark/admin/saveOrder", body);
export const getShop = async body => request("preMark/admin/getShop", body);
export const saveShop = async body => request("preMark/admin/saveShop", body);


 
 
 
 
 
 export const  cardPlayerList = async body => request("/card/playerList", body)
 export const  cardPlayerList2 = async body => request("/card/player/stats?season=2024&seasonType=2", body)
 export const  cardPlayerGraph = async body => request("/card/player/graph", body)
 export const  cardListQuery = async body => request("/card/list/query", body)
 export const  cardTableQuery = async body => request("/card/table/test", body)
 export const  playerDetailQuery = async body => request("/card/player/detail", body)
 
 export const  saveNews = async body => request("/news/save", body)
 export const  listNews = async body => request("/news/list", body)
 export const  newsOption = async body => request("/news/edit/options", body)
 export const  getNewsDetail = async body => request("/news/detail", body)