import React, {useEffect, useState} from 'react';
import {PageContainer, ProConfigProvider, ProLayout} from "@ant-design/pro-components";
import {Alert, Button, Dropdown} from "antd";
import {
    InfoCircleFilled, LogoutOutlined,
} from "@ant-design/icons";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {routes as reactRoutes} from "../../config/routes";
import props from "./props"
import {doCheck, doLogout} from "../../config/request";
import {useDispatch, useSelector} from "react-redux";

const LayoutPage = () => {
    
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const [pathname, setPathname] = useState(location.pathname);
    const [routes, setRoutes] = useState([])
    
    const {userInfo} = useSelector(state => state.user);
    
    // Test
    const [showAlert, setShowAlert] = useState(localStorage.getItem("showAlert") !== "false")
    
    const alert = (props, defaultDom) => (
        <>
            <Alert
                message={
                    <div
                        style={{
                            color: 'white',
                        }}
                    >
                        本网站提供的部分服务在你当前浏览器中无法使用，建议你更换为
                        Chrome 浏览器查看本网站。
                    </div>
                }
                icon={
                    <InfoCircleFilled
                        style={{
                            color: 'white',
                        }}
                    />
                }
                banner
                style={{
                    backgroundColor: 'black',
                }}
                action={
                    [
                        <Button
                            type="text"
                            style={{
                                color: 'white',
                            }}
                        >
                            查看详情
                        </Button>,
                        <Button
                            type="text"
                            style={{
                                color: 'white',
                            }}
                            onClick={() => {
                                localStorage.setItem("showAlert", "false")
                                setShowAlert(false)
                            }}
                        >
                            关闭
                        </Button>
                    ]
                }
            />
            {React.cloneElement(defaultDom, {
                style: {
                    height: '56px',
                    lineHeight: '56px',
                },
            })}
        </>
    )
    useEffect(() => {
        setPathname(location.pathname)
    },[location])
    useEffect(() => {
        if (localStorage.getItem("X-Access-Token")) {
            doCheck(dispatch).then(r => {
                if (r) {
                    setRoutes(reactRoutes[0].children.map(item => {
                        return {
                            path: item.index ? "/" : item.path,
                            name: item.name,
                            icon: item.icon,
                            routes: item?.children?.map(item => {
                                return {
                                    path: item.path,
                                    name: item.name,
                                    icon: item.icon,
                                }
                            })
                        }
                    }))
                } else {
                    navigate("/login")
                }
            })
        } else {
            navigate("/login")
        }
    }, [dispatch, navigate])
    
    const logout = () => {
        doLogout(dispatch).then(_ => navigate("/login"))
    }

    const onChangeMenu = (item) => () => {
        setPathname(item.path)
        navigate(item.path)
    }
    
    return (
        <>
            <ProConfigProvider dark={false}>
                <ProLayout
                    headerRender={showAlert ? alert : null}
                    // actionsRender={(props) => {
                    //     if (props.isMobile) return [];
                    //     return [
                    //         props.layout !== 'side' && document.body.clientWidth > 1400 ? (
                    //             <div
                    //                 key="SearchOutlined"
                    //                 aria-hidden
                    //                 style={{
                    //                     display: 'flex',
                    //                     alignItems: 'center',
                    //                     marginInlineEnd: 24,
                    //                 }}
                    //                 onMouseDown={(e) => {
                    //                     e.stopPropagation();
                    //                     e.preventDefault();
                    //                 }}
                    //             >
                    //                 <Input
                    //                     style={{
                    //                         borderRadius: 4,
                    //                         marginInlineEnd: 12,
                    //                         backgroundColor: 'rgba(0,0,0,0.03)',
                    //                     }}
                    //                     prefix={
                    //                         <SearchOutlined
                    //                             style={{
                    //                                 color: 'rgba(0, 0, 0, 0.15)',
                    //                             }}
                    //                         />
                    //                     }
                    //                     placeholder="搜索方案"
                    //                     bordered={false}
                    //                 />
                    //                 <PlusCircleFilled
                    //                     style={{
                    //                         color: 'var(--ant-primary-color)',
                    //                         fontSize: 24,
                    //                     }}
                    //                 />
                    //             </div>
                    //         ) : undefined,
                    //         <QuestionCircleFilled key="QuestionCircleFilled" />,
                    //     ];
                    // }}
                    menuFooterRender={(props) => {
                        if (props?.collapsed) return undefined;
                        return (
                            <div
                                style={{
                                    textAlign: 'center',
                                    paddingBlockStart: 12,
                                }}
                            >
                                <div>© 2020-2023 ULink Co.</div>
                                <div>Designed By Ethan Hong</div>
                            </div>
                        );
                    }}
                    menuItemRender={(item, dom) => (
                        <div onClick={onChangeMenu(item)}>
                            {dom}
                        </div>
                    )}
                    token={{
                        header: {
                            heightLayoutHeader: showAlert ? 108 :null,
                            // colorTextRightActionsItem: '#000',
                            colorHeaderTitle: '#000',
                            colorBgMenuItemHover: 'rgba(0,0,0,0)',
                            colorTextMenuSelected: '#863bf2',
                            colorTextMenuActive: '#863bf2',
                        },
                    }}
                    avatarProps={{
                        src: props.avatar,
                        title: userInfo.nickName,
                        render: (_, dom) =>
                            <Dropdown
                                placement="bottom"
                                menu={{
                                    items: [
                                        {
                                            key: '1',
                                            label:
                                                <div onClick={logout}>
                                                    <LogoutOutlined /> 退出登录
                                                </div>
                                            ,
                                        }
                                    ]
                                }}
                            >
                                {dom}
                            </Dropdown>
                    }}
                    onMenuHeaderClick={onChangeMenu({path: "/"})}
                    splitMenus={true}
                    fixSiderba={true}
                    layout='mix'
                    bgLayoutImgList={props.bgLayoutImgList}
                    // appList={props.appList}
                    title="Ulink Technology"
                    logo="https://gw.alipayobjects.com/zos/antfincdn/FLrTNDvlna/antv.png"
                    route={{routes}}
                    location={{pathname}}
                    menu={{type: 'group'}}
                >
                    <PageContainer>
                        <Outlet />
                    </PageContainer>
                </ProLayout>
            </ProConfigProvider>
        </>
    )
}

export default LayoutPage;
