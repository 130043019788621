import {Button, Checkbox, Form, Input, InputNumber, message, Spin, Switch, Upload} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useContext, useEffect, useState} from "react";
import {DataContext} from "../../store/DataContext";
import {useSelector} from "react-redux";
import {getShop, saveShop} from "../../config/api";

const Details = () => {
    const [componentDisabled, setComponentDisabled] = useState(false);
    const [shop, setShop] = useState()
    const {darkMode} = useContext(DataContext);
    const [loading, setLoading] = useState(true)
    const {userInfo} = useSelector(state => state.user);
    const [form] = Form.useForm();
    useEffect(() => {
        getShop().then(r => {
            console.log(r)
            setShop(r)
            setLoading(false)
            // form.setFieldsValue(r)
        })
    }, [])
    const normFile = (e) => {
        console.log(e)
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    const onFinish = (values) => {
        console.log(values)
        let data = {...values}
        if (data.avatarImage?.[0]?.status === "uploading" || data.homeImage?.[0]?.status === "uploading") {
            message.info("文件上传中")
            return
        }
        if (data.avatarImage.length === 0) {
            message.error("头像图片不能为空")
            return
        }
        if (data.homeImage.length === 0) {
            message.error("主页图片不能为空")
            return
        }
        if (data.shopName === "") {
            message.error("商铺名称不能为空")
            return
        }
        let newShop = {
            ...shop,
            name: data.shopName,
            price: [data.firstPrice, data.secondPrice, data.thirdPrice, data.fourPrice,data.fivePrice],
            avatarImage: data.avatarImage?.[0].status ? data.avatarImage?.[0].response : data.avatarImage?.[0].url,
            homeImage: data.homeImage?.[0].status ? data.homeImage?.[0].response : data.homeImage?.[0].url,
            status: data.isTrade ? 1 : 0
        }
        saveShop({...newShop}).then(r => {
            console.log(r)
            message.success("修改成功")
            setComponentDisabled(false)
        })
    }
    if (loading) {
        return <Spin spinning={loading} fullscreen="true" tip="loading..."></Spin>
    }
    return <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
        <Form
            labelCol={{
                span: 4,
            }}
            wrapperCol={{
                span: 14,
            }}
            layout="horizontal"
            disabled={!componentDisabled}
            style={{
                maxWidth: 600,
            }}
            onFinish={onFinish}
            initialValues={{
                shopName: shop?.name,
                firstPrice: shop?.price?.[0],
                secondPrice: shop?.price?.[1],
                thirdPrice: shop?.price?.[2],
                fourPrice: shop?.price?.[3],
                fivePrice: shop?.price?.[4],
                avatarImage: [{
                    name: "origin",
                    type: "image/png",
                    url: shop?.avatarImage
                }],
                homeImage: [{
                    name: "origin",
                    type: "image/png",
                    url: shop?.homeImage
                }],
                isTrade: shop?.status === 1
            }}
            form={form}

        >
            <Form.Item name="shopName" label="店铺名称">
                <Input/>
            </Form.Item>
            <Form.Item name="firstPrice" label="0-500">
                <InputNumber min="0.01"/>
            </Form.Item>
            <Form.Item name="secondPrice" label="500-2000">
                <InputNumber min="0.01"/>
            </Form.Item>
            <Form.Item name="thirdPrice" label="2000-8000">
                <InputNumber min="0.01"/>
            </Form.Item>
            <Form.Item name="fourPrice" label="8000-15000">
                <InputNumber min="0.01"/>
            </Form.Item>
            <Form.Item name="fivePrice" label="15000+">
                <InputNumber min="0.01"/>
            </Form.Item>
            <Form.Item name="avatarImage" label="头像图片" valuePropName="fileList" getValueFromEvent={normFile}>
                <Upload action="https://aiviz.cn/api/aiviz/playground/upload"
                        listType="picture-card"
                        maxCount={1}
                        beforeUpload={(file) => {
                            const typeArr = ["image/png", "image/jpeg", "image/webp", "image/bmp"]
                            const isPNG = typeArr.includes(file.type)
                            if (!isPNG) {
                                message.error(`${file.name} 不是图片`);
                            }
                            return isPNG || Upload.LIST_IGNORE;
                        }}
                >
                    <button
                        style={{
                            border: 0,
                            background: 'none',
                        }}
                        type="button"
                    >
                        <PlusOutlined style={{color: darkMode ? "white" : "black"}}/>
                        <div
                            style={{
                                marginTop: 8,
                                color: darkMode ? "white" : "black"
                            }}
                        >
                            Upload
                        </div>
                    </button>
                </Upload>
            </Form.Item>
            <Form.Item name="homeImage" label="主页图片" valuePropName="fileList" getValueFromEvent={normFile}>
                <Upload
                    action="https://aiviz.cn/api/aiviz/playground/upload"
                    listType="picture-card"
                    maxCount={1}
                    beforeUpload={(file) => {
                        const typeArr = ["image/png", "image/jpeg", "image/webp", "image/bmp"]
                        const isPNG = typeArr.includes(file.type)
                        if (!isPNG) {
                            message.error(`${file.name} 不是图片`);
                        }
                        return isPNG || Upload.LIST_IGNORE;
                    }}>
                    <button
                        style={{
                            border: 0,
                            background: 'none',
                        }}
                        type="button"
                    >
                        <PlusOutlined style={{color: darkMode ? "white" : "black"}}/>
                        <div
                            style={{
                                marginTop: 8,
                                color: darkMode ? "white" : "black"
                            }}
                        >
                            Upload
                        </div>
                    </button>
                </Upload>
            </Form.Item>
            <Form.Item name="isTrade" label="是否营业" >
                <Switch checkedChildren="开启" unCheckedChildren="关闭" />
            </Form.Item>
            <Form.Item style={{display: "flex", justifyContent: "flex-end"}} layout="horizontal">
                <Button type="primary" htmlType="submit">
                    提交
                </Button>
            </Form.Item>
        </Form>
        <Checkbox
            checked={componentDisabled}
            onChange={(e) => setComponentDisabled(e.target.checked)}
        >
            编辑
        </Checkbox>
    </div>
}
export default Details