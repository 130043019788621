import React, {useEffect, useRef, useState} from "react";
import {ProTable} from "@ant-design/pro-components";
import {getInfoFromEspn, getInfoFromEspnAll, getPlayerList, getSportTypeList, savePlayer} from "../../config/api";
import {Button, Checkbox, Form, Input, message, Modal, Popconfirm, Select, Space, Table} from "antd";
import {Loading3QuartersOutlined, LoadingOutlined, UserAddOutlined} from "@ant-design/icons";
import {useRequest} from "ahooks";

function CheckboxGroup(props) {
    return null;
}

const Page = () => {
    const [data, setData] = useState([])
    const [data1, setData1] = useState([])
    const [sportType, setSportType] = useState(localStorage.getItem("playerListSportType") !== null ? localStorage.getItem("playerListSportType") : "NBA")
    const actionRef = useRef();
    const [addPlayerModal, setAddPlayerModalOpen] = useState(false);

    const {loading: getDataLoading, runAsync: runGetData} = useRequest(getPlayerList, {manual: true});
    const {loading: savePlayerLoading, runAsync: runSavePlayer} = useRequest(savePlayer, {manual: true});
    const {loading: getInfoFromEspnLoading, runAsync: runGetInfoFromEspn} = useRequest(getInfoFromEspn, {manual: true});
    const {
        loading: getInfoFromEspnAllLoading,
        runAsync: runGetInfoFromEspnAll
    } = useRequest(getInfoFromEspnAll, {manual: true});
    const [getInfoFromEspnIndex, setGetInfoFromEspnIndex] = useState("");


    useEffect(() => {
        requestTableData()
    }, [])

    const requestTableData = (select) => {
        runGetData({value: select ? select : sportType}).then(async (r) => {
            let sportTypeList
               await getSportTypeList().then(r => {
                console.log(r.sportTypeList)
                    sportTypeList = r.sportTypeList.map(item => {
                        return {key: item,isCheck: item === "NBA"}
                    })
            })
            let list = r.map(item => {
                return {...item,sportTypeList}
            })
            setData(list.sort((a, b) => b.sortIndex - a.sortIndex))
            setData1(list.sort((a, b) => b.sortIndex - a.sortIndex))
        })
    }

    const onFinish = (values) => {
        let add = {...values, sportType}
        console.log(add)
        //缺少id
        setData([...data, add])
        setAddPlayerModalOpen(false);
        runSavePlayer(add).then(r => {
            getPlayerList({value: sportType}).then(r => {
                setData(r.sort((a, b) => b.sortIndex - a.sortIndex))
            })
        })
    };
    const saveEdite = (key, row) => {
        const index = data.findIndex(item => item.id === key)
        let newData = [...data]
        newData[index] = row
        setData(newData)
        setData1(newData)
        ///接口更改

        savePlayer(row).then(r => {
            console.log(r)
        })
    }
    const deleteItem = (key, row) => {
        const index = data.findIndex(item => item.id === key)
        let newData = [...data]
        newData.splice(index, index + 1)
        setData(newData)
        setData1(newData)
        //接口删除
    }

    const sportTypeOptions = [
        "NBA",
        "足球",
        "NFL",
        "MLB",
    ]
    const expandedRowRender = (record) => {
        const list = record.sportTypeList
        const onCheckType = (e,index,id) => {
            let newData = [...data]
            let index2 = newData.findIndex(item => item.id === id)
            newData[index2].sportTypeList[index].isCheck = e.target.checked
            setData(newData)
            setData1(newData)
        }
        return <div style={{marginLeft: "4px"}}>
            {
                list.map((item,index) => {
                    return <Checkbox checked={item.isCheck} onChange={(e) => onCheckType(e,index,record.id)} >{item.key}</Checkbox>
                })
            }
            </div>

    };
    const searchList = (values) => {
        let newData = [...data1]
        let keys = Object.keys(values)
        for (let key of keys) {
            if (values[key] !== ""){
                newData = newData.filter(item => {
                    return item[key]?.toLowerCase().includes(values[key].toLowerCase())
                })
            }
        }
        setData(newData)
    }
    const columns = [
        {
            title: '名字',
            dataIndex: 'name',
            width: 200,
            copyable: true,
            ellipsis: true,
        },
        {
            title: '中文名',
            dataIndex: 'chineseName',
            width: 200,
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: '排序',
            dataIndex: 'sortIndex',
            width: 100,
            hideInSearch: true
        },
        {
            title: '关键词',
            dataIndex: 'queryKeys',
            width: 200,
            render:(text, record, _, action) => {
                return <div>{text}</div>
            }
        },
        {
            title: '昵称',
            dataIndex: 'nickName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '队名',
            dataIndex: 'teamName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '新秀年',
            dataIndex: 'debutYear',
            width: 100,
            ellipsis: true,
        },
        {
            title: '位置',
            dataIndex: 'position',
            width: 100,
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: '编号',
            dataIndex: 'number',
            width: 100,
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: '球员图片',
            dataIndex: 'peopleImage',
            width: 200,
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: 'ESPN Id',
            dataIndex: 'espnId',
            width: 200,
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: 'ESPN链接',
            dataIndex: 'espnPeopleUrl',
            width: 200,
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: '身高',
            dataIndex: 'height',
            width: 120,
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: '体重',
            dataIndex: 'weight',
            width: 120,
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: '生日',
            dataIndex: 'birthday',
            width: 200,
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: '大学',
            dataIndex: 'college',
            ellipsis: true,
            width: 200,
            hideInSearch: true
        },
        {
            title: '出生地',
            dataIndex: 'birthplace',
            ellipsis: true,
            width: 200,
            hideInSearch: true
        },
        {
            title: '选秀信息',
            dataIndex: 'draft',
            ellipsis: true,
            width: 300,
            hideInSearch: true
        },
        // {
        //     title: '关联',
        //     valueType: 'option',
        //     key: 'option',
        //     align: 'center',
        //     fixed: 'right',
        //     width: 125,
        //     render: (text, record, _, action) => {
        //         // console.log(text)
        //         // console.log(record)
        //         // console.log(action)
        //         const plainOptions = ['Apple', 'Pear', 'Orange'];
        //         const defaultCheckedList = ['Apple', 'Orange'];
        //         // const [checkedList, setCheckedList] = useState(defaultCheckedList);
        //         // const onChange = (list) => {
        //         //     setCheckedList(list);
        //         // };
        //         return <div>
        //             <Checkbox>银折</Checkbox>
        //             <Checkbox>国宝</Checkbox>
        //             <Checkbox>熊猫</Checkbox> <Checkbox>银折</Checkbox>
        //             <Checkbox>国宝</Checkbox>
        //             <Checkbox>熊猫</Checkbox> <Checkbox>银折</Checkbox>
        //         </div>
        //
        //     }
        //     //     [
        //     //     <div
        //     //         className="text-button"
        //     //         onClick={() => {
        //     //             action?.startEditable?.(record.id);
        //     //         }}
        //     //     >
        //     //         编辑
        //     //     </div>,
        //     //     <div
        //     //         className="text-button"
        //     //         onClick={() => {
        //     //             if (record.name !== null) {
        //     //                 message.error("请勿重复抓取").then()
        //     //                 return
        //     //             }
        //     //
        //     //             if (getInfoFromEspnLoading) {
        //     //                 message.info("正在同步中").then()
        //     //                 return
        //     //             }
        //     //
        //     //             setGetInfoFromEspnIndex(record.id)
        //     //             runGetInfoFromEspn(record).then(
        //     //                 r => {
        //     //                     const index = data.findIndex(item => item.id === record.id)
        //     //                     let newData = [...data]
        //     //                     newData[index] = r
        //     //                     setData(newData)
        //     //                     setGetInfoFromEspnIndex(null)
        //     //                 }
        //     //
        //     //             )
        //     //         }}
        //     //     >
        //     //         {/*同步ESPN*/}
        //     //         {getInfoFromEspnIndex === record.id ? <LoadingOutlined />  : "同步ESPN"}
        //     //     </div>
        //     // ],
        // },
    ];

    return (
        <div>
            <ProTable
                loading={getDataLoading || savePlayerLoading || getInfoFromEspnAllLoading}
                rowKey="id"
                expandedRowRender={expandedRowRender}
                headerTitle={
                    <>
                        <div style={{fontSize: 16}}>运动类型：</div>
                        <Select
                            style={{width: 200}}
                            value={sportType}
                            onChange={(e) => {
                                setSportType(e)
                                localStorage.setItem("playerListSportType", e)
                                requestTableData(e)
                            }}
                            options={sportTypeOptions.map(item => ({label: item, value: item}))}
                        />
                    </>
                }
                onSubmit={searchList}
                actionRef={actionRef}
                dataSource={data}
                columns={columns}
                options={{
                    reload: () => {
                        requestTableData()
                    }
                }}
                editable={{
                    type: 'multiple',
                    onSave: (key, row) => saveEdite(key, row),
                    onDelete: (key, row) => deleteItem(key, row)
                }}
                scroll={{x: 3500}}
            />
        </div>
    );
}

export default Page;
