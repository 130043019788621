import React, {useContext, useEffect, useState} from 'react';
import './openPrize.css';
import {DataContext} from "../../store/DataContext";
import {getAllTicket, getOpenPhase, getPhase, openPrize} from "../../config/api";
import {Spin} from "antd"; // 导入CSS样式

const OpenPrize = () => {
    const {darkMode, setDarkMode} = useContext(DataContext);
    const [moveClassArr, setMoveClassArr] = useState([-1, -1, -1, -1, -1, -1])
    const [phase, setPhase] = useState("")
    const [prizeNumber, setPrizeNumber] = useState([])
    const [sumTicket, setSumTicket] = useState()
    const [loading, setLoading] = useState(true)

    // 获取当前周数的函数
    function getCurrentWeekNumber() {
        const currentDate = new Date();
        const startDate = new Date(currentDate.getFullYear(), 0, 1);
        const startWeekDayOfYear = startDate.getDay() || 7; // 获取年份第一天是星期几，周日需要特殊处理为7

        // 计算今天是本年的第几天
        const dayOfYear = Math.floor((currentDate - startDate + (startDate.getTimezoneOffset() - currentDate.getTimezoneOffset()) * 60 * 1000) / 86400000) + 1;

        // 计算当前是第几周
        return Math.ceil((dayOfYear + startWeekDayOfYear - 1) / 7);
    }

    const spin = () => {
        let num1 = Math.floor(Math.random() * 10)
        let num2 = Math.floor(Math.random() * 10)
        let num3 = Math.floor(Math.random() * 10)
        let num4 = Math.floor(Math.random() * 10)
        let num5 = Math.floor(Math.random() * 10)
        let num6 = Math.floor(Math.random() * 10)
        console.log(num1, num2, num3, num4, num5, num6)
        // setMoveClassArr([num1,num2,num3,num4,num5,num6])
        setMoveClassArr(prizeNumber)
    };
    useEffect(() => {
        // 获取当前周数
        let weekNumber = getCurrentWeekNumber() - 31

        // 将周数格式化为两位数
        let formattedWeekNumber = weekNumber.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
        getPhase().then(r => {
            console.log(r)
        })
        getOpenPhase().then(r => {
            console.log(r)
            setPhase(r)
            setLoading(false)
        })
        getAllTicket().then(r => {
            setSumTicket(r.length)
        })
        openPrize().then(r => {
            console.log(r)
            let arr = r.split('')
            setPrizeNumber(arr)
        })
    }, []);
    return (
        <Spin spinning={loading} tip="loading..." fullscreen={loading} size="large">
                <div style={{display: "flex", flexDirection: 'column', alignItems: 'center', padding: "30px"}}>
                    <div
                        style={{
                            width: "100%",
                            fontSize: 18,
                            color: 'white',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: "flex-start"
                        }}
                    >
                        <div>当前期数: <text style={{fontWeight: 600}}> {phase}</text></div>
                        <div>当前参与人数: <text style={{fontWeight: 600}}> {sumTicket}</text>人</div>
                    </div>
                    <div
                        style={{borderRadius: 10, marginTop: 30, padding: 10, backgroundColor: 'gray'}}
                    >
                        <div
                            style={{
                                height: "60px",
                                overflow: "hidden",
                                // marginTop: '75px',
                                fontSize: "60px",
                                color: darkMode ? "#FFFFFF" : 'black',
                                lineHeight: "60px",
                                display: 'flex',
                                justifyContent: 'center',
                                gap: '30px'
                            }}>
                            {/*<div>0</div>*/}
                            {/*<div>0</div>*/}
                            {/*<div>1</div>*/}
                            {
                                phase.split('').map(item => {
                                    return <div>{item}</div>
                                })
                            }
                            <div>-</div>
                            {
                                moveClassArr.map((item, index) => {

                                    return <div key={index} className={`move-${item}`}>
                                        {
                                            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(item =>
                                                <div>{item}</div>)
                                        }
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    <button className="spin-button" onClick={spin}>
                        开始抽奖
                    </button>
                </div>
        </Spin>
    );
};

export default OpenPrize;