import axios from "axios";
import {setUserInfo} from "../store/userSlice";

export const initAxios = dispatch => {
    axios.defaults.validateStatus = status => {
        [400, 401, 500, 504].includes(status) && dispatch(setUserInfo({}))
        return true
    }
}
let mobileShopArr = ["13133815956"]
export const doLogin = async (body, dispatch) => {
    let resp = true
    await request("user/login?type=web", body).then(r => {
        console.log(r)
        if (r?.result) {
            setToken(r.token)
            dispatch(setUserInfo(r.userInfo))
            if (r.userInfo.role === 12) {
                resp = "shopAdmin"
            }
        } else {
            setToken("")
            resp = false
        }
    })
    return resp
};

export const doCheck = async (dispatch) => {
    let resp = true
    await request("user/check").then(r => {
        if (r?.result) {
            setToken(r.token)
            dispatch(setUserInfo(r.userInfo))
            if (r.userInfo.role === 12) {
                resp = false
            }
        } else {
            setToken("")
            resp = false
        }
    })
    return resp
}
export const doCheckShop = async (dispatch) => {
    let resp = true
    await request("user/check").then(r => {
        console.log(r)
        if (r?.result) {
            setToken(r.token)
            dispatch(setUserInfo(r.userInfo))
            if (r.userInfo.role !== 12) {
                resp = false
            }
        } else {
            setToken("")
            resp = false
        }
    })
    return resp
}
export const doLogout = async (dispatch) => {
    await request("user/logout").then(() => {
        setToken("")
        dispatch(setUserInfo({}))
    })
};

let token = localStorage.getItem("X-Access-Token") ?? ""
export const setToken = (newToken) => {
    token = newToken
    localStorage.setItem("X-Access-Token", newToken)
}
export const request = async (url, body) => {
    return (await axios.post("/api/" + url, body,
        {
            headers: {
                "X-Access-Token": token
            }
        })).data.object
}