// import React, {useState} from 'react';
// import { DownOutlined } from '@ant-design/icons';
// import {Badge, Button, Dropdown, Form, Input, InputNumber, Popconfirm, Space, Table, Typography} from 'antd';
// const CardType = () => {
//     const [form] = Form.useForm();
//     const [typeList, setTypeList] = useState([{type: "银折",searchKeywords: ["搜索1","搜索2"]},{type: "国宝",searchKeywords: ["搜索3","搜索4"]}]);
//     const [expandRow, setExpandRow] = useState()
//     const [editingKey, setEditingKey] = useState('');
//     const save = async (key) => {
//         try {
//             const row = await form.validateFields();
//             const newData = [...typeList];
//             const index = newData.findIndex((item) => key === item.key);
//             if (index > -1) {
//                 const item = newData[index];
//                 newData.splice(index, 1, {
//                     ...item,
//                     ...row,
//                 });
//                 setTypeList(newData);
//                 setEditingKey('');
//             } else {
//                 newData.push(row);
//                 setTypeList(newData);
//                 setEditingKey('');
//             }
//         } catch (errInfo) {
//             console.log('Validate Failed:', errInfo);
//         }
//     };
//     const edit = (record) => {
//         form.setFieldsValue({
//             cardType: '',
//             ...record,
//         });
//         setEditingKey(record.key);
//     };
//     const cancel = () => {
//         setEditingKey('');
//     };
//     const expandedRowRender = (record) => {
//             const columns = [
//                 {
//                     title: '搜索词',
//                     dataIndex: 'searchKeywords',
//                     key: 'searchKeywords',
//                 },
//                 {
//                     title: 'Action',
//                     key: 'operation',
//                     render: () => (
//                         <Space size="middle">
//                             <a>Pause</a>
//                         </Space>
//                     ),
//                 },
//             ];
//             const data = [];
//             record.searchList.forEach((item,index) => {
//                 data.push({
//                     key: index,
//                     searchKeywords: item
//                 })
//             })
//             return <>
//                 <Button
//                 // onClick={handleAdd}
//                 type="primary"
//                 size="middle"
//             >
//                 Add a row
//                 </Button>
//                 <Table columns={columns} dataSource={data} pagination={false} />
//             </>
//
//     };
//     const columns = [
//         {
//             title: '卡片类型',
//             dataIndex: 'cardType',
//             key: 'cardType',
//             editable:true
//         },
//         {
//             title: 'operation',
//             dataIndex: 'operation',
//             render: (_, record) => {
//                 const editable = isEditing(record);
//                 return editable ? (
//                     <span>
//             <Typography.Link
//                 onClick={() => save(record.key)}
//                 style={{
//                     marginRight: 8,
//                 }}
//             >
//               Save
//             </Typography.Link>
//             <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
//               <a>Cancel</a>
//             </Popconfirm>
//           </span>
//                 ) : (
//                     <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
//                         Edit
//                     </Typography.Link>
//                 );
//             },
//         },
//         // {
//         //     title: '操作',
//         //     key: 'operation',
//         //     render: () => (
//         //         <Space size="middle">
//         //             <a>编辑</a>
//         //             <a onClick={null}>删除</a>
//         //         </Space>
//         //     ),
//         // },
//     ];
//     const mergedColumns = columns.map((col) => {
//         if (!col.editable) {
//             return col;
//         }
//         return {
//             ...col,
//             onCell: (record) => ({
//                 record,
//                 inputType: col.dataIndex = 'text',
//                 dataIndex: col.dataIndex,
//                 editing: isEditing(record),
//             }),
//         };
//     });
//     const EditableCell = ({
//                               editing,
//                               dataIndex,
//                               title,
//                               inputType,
//                               record,
//                               index,
//                               children,
//                               ...restProps
//                           }) => {
//         console.log(dataIndex)
//         console.log(record)
//         const inputNode = inputType === 'number' ? <InputNumber/> : <Input />;
//         return (
//             <td {...restProps}>
//                 {editing ? (
//                     <Form.Item
//                         name={dataIndex}
//                         style={{
//                             margin: 0,
//                         }}
//                         rules={[
//                             {
//                                 required: true,
//                                 message: `Please Input ${title}!`,
//                             },
//                         ]}
//                     >
//                         {inputNode}
//                     </Form.Item>
//                 ) : (
//                     children
//                 )}
//             </td>
//         );
//     };
//     const data = [];
//         data.push({
//             key: 'yinzhe',
//             cardType: '银折',
//             searchList: ["搜索词1","搜索词2","搜索词3","搜索词4",]
//         });
//         data.push({
//             key: "guobao",
//             cardType: '国宝',
//             searchList: ["搜索词5","搜索词6","搜索词7","搜索词8",]
//         });
//     const expandedChange = (row) => {
//         setExpandRow(row)
//     }
//     const isEditing = (record) => record.key === editingKey;
//     return (
//         <>
//             <Button
//                 // onClick={handleAdd}
//                 type="primary"
//                 style={{
//                     marginBottom: 16,
//                 }}
//             >
//                 Add a row
//             </Button>
//             <Form form={form} component={false}>
//                 <Table
//                     components={{
//                         body: {
//                             cell: EditableCell,
//                         },
//                     }}
//                     bordered
//                     columns={mergedColumns}
//                     expandable={{
//                         expandedRowRender,
//                         // defaultExpandedRowKeys: ['0'],
//                         expandedRowKeys: expandRow,
//                         //expandRowByClick: true,
//                         onExpandedRowsChange: expandedChange
//                     }}
//                     dataSource={data}
//                     size="big"
//                     pagination={false}
//                     rowClassName="editable-row"
//                 />
//             </Form>
//
//         </>
//     );
// };
// export default CardType;
import React, {useEffect, useState} from 'react';
import {Button, Form, Input, InputNumber, Modal, Popconfirm, Space, Table, Typography} from 'antd';
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {getSportTypeList} from "../../config/api";

const originData = [];
for (let i = 0; i < 100; i++) {
    originData.push({
        key: i.toString(),
        name: `Edward ${i}`,
        age: 32,
        address: `London Park no. ${i}`,
    });
}
const EditableCell = ({
                          editing,
                          dataIndex,
                          title,
                          inputType,
                          record,
                          index,
                          children,
                          ...restProps
                      }) => {
    const inputNode = inputType === 'number' ? <InputNumber/> : <Input/>;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};
const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 4,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 20,
        },
    },
};
const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 20,
            offset: 4,
        },
    },
};
const CardType = () => {
    const [form] = Form.useForm();
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [addKeyWordsRowKey, setAddKeyWordsRowKey] = useState()
    const [data, setData] = useState([{key: "银折", cardType: "银折", searchKeywords: ["搜索1", "搜索2"]}, {
        key: "国宝",
        cardType: "国宝",
        searchKeywords: ["搜索3", "搜索4"]
    }]);
    const [editingKey, setEditingKey] = useState('');
    const isEditing = (record) => record.key === editingKey;
    const edit = (record) => {
        form.setFieldsValue({
            ...record,
        });
        setEditingKey(record.key);
    };
    const cancelEdite = () => {
        setEditingKey('');
    };
    const showModal = (key) => {
        if (key === 1) {
            setOpen1(true);
        } else setOpen2(true);
    };
    const hideModal = (key) => {
        if (key === 1) {
            setOpen1(false);
        } else setOpen2(false);

    };
    const expandedRowRender = (record) => {
        const rowKey = record.key
        const columns = [
            {
                title: '搜索词',
                dataIndex: 'searchKeyword',
                editable: true,
            },
            {
                title: '操作',
                dataIndex: 'operation',
                render: (_, record) => {
                    console.log(record)
                    console.log(rowKey)
                    return <Space size="middle">
                        <Popconfirm title="确定要删除？" onConfirm={() => deleteItem2(rowKey, record.key)}>
                            <a>删除</a>
                        </Popconfirm>
                    </Space>
                }
            },
        ];
        const data = [];
        record.searchKeywords.forEach((item, index) => {
            data.push({
                key: index,
                searchKeyword: item
            })
        })
        return <>
            <Button
                // onClick={handleAdd}
                type="primary"
                size="middle"
                onClick={() => {
                    showModal(2)
                    setAddKeyWordsRowKey(rowKey)
                }}
                style={{backgroundColor: "rgb(134,59,242,0.9)",fontSize: "12px"}}
            >
                添加搜索词
            </Button>
            <Table columns={columns} dataSource={data} pagination={false}/>
        </>

    };
    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                    key: row.cardType
                });
                setData(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };
    const deleteItem = (key) => {
        console.log(key)
        let filterData = data.filter(item => item.key !== key)
        setData(filterData)
        console.log(filterData)
    }
    const deleteItem2 = (rowKey, minRowKey) => {
        let index = data.findIndex(item => item.key === rowKey)
        let filterData = [...data]
        filterData[index].searchKeywords = filterData[index].searchKeywords.filter((item, index) => index !== minRowKey)
        setData(filterData)
    }
    const addCardType = (values) => {
        console.log(values)
        let newItem = values
        newItem.key = values.cardType
        if (newItem.searchKeywords === undefined) {
            newItem.searchKeywords = []
        }
        setData([...data, newItem])
        hideModal(1)
    }
    const addSearchKeywords = (values) => {
        console.log(values)
        let data1 = [...data]
        if (values.searchKeywords) {
            let index = data.findIndex(item => item.key === addKeyWordsRowKey)
            data1[index].searchKeywords= [...data1[index].searchKeywords,...values.searchKeywords]
           setData(data1)
        }
        hideModal(2)
    }

    const columns = [
        {
            title: '卡片种类',
            dataIndex: 'cardType',
            width: '25%',
            editable: true,
        },
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_, record) => {
                console.log(record)
                const editable = isEditing(record);
                return editable ? (
                    <span>
            <Typography.Link
                onClick={() => save(record.key)}
                style={{
                    marginRight: 8,
                }}
            >
              保存
            </Typography.Link>
            <Popconfirm title="确定取消编辑？" onConfirm={cancelEdite}>
              <a>取消</a>
            </Popconfirm>
          </span>
                ) : (
                    <><Space size="middle">
                        <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                            编辑
                        </Typography.Link>
                        <Popconfirm title="确定要删除？" onConfirm={() => deleteItem(record.key)}>
                            <a>删除</a>
                        </Popconfirm>
                    </Space>


                    </>
                );
            },
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
    return (<>
            <Button
                // onClick={handleAdd}
                type="primary"
                size="middle"
                onClick={() => showModal(1)}
            >
                添加卡种
            </Button>
            <Modal
                title="添加卡种"
                open={open1}
                onOk={() => hideModal(1)}
                onCancel={() => hideModal(1)}
                okText="确认"
                cancelText="取消"
                footer={false}
            >
                <div style={{display: "flex", alignItems: "flex-start",}}>
                    <div style={{marginRight: "-60px"}}>
                        <div style={{lineHeight: "32px",}}>卡片种类 :</div>
                        <div style={{lineHeight: "32px", margin: "22px 0 0 0", width: "62px", textAlign: "right"}}>搜索词
                            :
                        </div>
                    </div>
                    <Form
                        name="dynamic_form_item"
                        {...formItemLayoutWithOutLabel}
                        onFinish={addCardType}
                        style={{
                            maxWidth: 600,
                            flex: 1,
                        }}
                    >
                        <Form.Item
                            name="cardType"
                            rules={[
                                {
                                    required: true,
                                    message: `请输入卡片类型`,
                                },
                            ]}
                        >
                            <Input

                                placeholder="卡片种类"
                                style={{
                                    width: '60%',
                                }}

                            />
                        </Form.Item>
                        <Form.List
                            name="searchKeywords"
                            // rules={[
                            //     {
                            //         validator: async (_, names) => {
                            //             if (!names || names.length < 2) {
                            //                 return Promise.reject(new Error('At least 2 passengers'));
                            //             }
                            //         },
                            //     },
                            // ]}
                        >
                            {(fields, {add, remove}, {errors}) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Form.Item
                                            {...formItemLayoutWithOutLabel}
                                            // label={index === 0 ? '搜索词' : ''}
                                            required={false}
                                            // key={field.key}
                                        >
                                            <Form.Item
                                                {...field}
                                                validateTrigger={['onChange', 'onBlur']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "请输入搜索词"
                                                    },
                                                ]}
                                                noStyle
                                            >
                                                <Input
                                                    placeholder="搜索词"
                                                    style={{
                                                        width: '60%',
                                                    }}
                                                />
                                            </Form.Item>
                                            {fields.length > 0 ? (
                                                <MinusCircleOutlined
                                                    className="dynamic-delete-button"
                                                    style={{marginLeft: "5px"}}
                                                    onClick={() => remove(field.name)}
                                                />
                                            ) : null}
                                        </Form.Item>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            style={{
                                                width: '60%'
                                            }}
                                            icon={<PlusOutlined/>}
                                        >
                                            添加搜索词
                                        </Button>
                                        <Form.ErrorList errors={errors}/>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                        <Form.Item style={{
                            display: "flex",
                            marginBottom: "-10px",
                            justifyContent: "flex-end",
                            paddingRight: "10px"
                        }}>
                            <Button type="primary" htmlType="submit">
                                确认
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
            <Modal
                title="添加搜索词"
                open={open2}
                onOk={() => hideModal(2)}
                onCancel={() => hideModal(2)}
                okText="确认"
                footer={false}
                cancelText="取消"
            >
                <div style={{display: "flex", alignItems: "flex-start", paddingTop: "20px"}}>
                    <div style={{lineHeight: "32px", margin: "0 -60px 0 0", width: "62px", textAlign: "right"}}>搜索词 :
                    </div>
                    <Form
                        name="dynamic_form_item"
                        {...formItemLayoutWithOutLabel}
                        onFinish={addSearchKeywords}
                        style={{
                            maxWidth: 600,
                            flex: 1,
                        }}
                    >
                        <Form.List
                            name="searchKeywords"
                            // rules={[
                            //     {
                            //         validator: async (_, names) => {
                            //             if (!names || names.length < 2) {
                            //                 return Promise.reject(new Error('At least 2 passengers'));
                            //             }
                            //         },
                            //     },
                            // ]}
                        >
                            {(fields, {add, remove}, {errors}) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Form.Item
                                            {...formItemLayoutWithOutLabel}
                                            // label={index === 0 ? '搜索词' : ''}
                                            required={false}
                                            // key={field.key}
                                        >
                                            <Form.Item
                                                {...field}
                                                validateTrigger={['onChange', 'onBlur']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "请输入搜索词"
                                                    },
                                                ]}
                                                noStyle
                                            >
                                                <Input
                                                    placeholder="搜索词"
                                                    style={{
                                                        width: '60%',
                                                    }}
                                                />
                                            </Form.Item>
                                            {fields.length > 0 ? (
                                                <MinusCircleOutlined
                                                    className="dynamic-delete-button"
                                                    style={{marginLeft: "5px"}}
                                                    onClick={() => remove(field.name)}
                                                />
                                            ) : null}
                                        </Form.Item>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            style={{
                                                width: '60%',
                                            }}
                                            icon={<PlusOutlined/>}
                                        >
                                            添加搜索词
                                        </Button>
                                        <Form.ErrorList errors={errors}/>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                        <Form.Item style={{
                            display: "flex",
                            marginBottom: "-10px",
                            justifyContent: "flex-end",
                            paddingRight: "10px"
                        }}>
                            <Button type="primary" htmlType="submit">
                                确认
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
            <Form form={form} component={false}>
                <Table
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    bordered
                    dataSource={data}
                    expandable={{
                        expandedRowRender,
                        // defaultExpandedRowKeys: ['0'],
                        // expandedRowKeys: expandRow,
                        //expandRowByClick: true,
                        // onExpandedRowsChange: expandedChange
                    }}
                    columns={mergedColumns}
                    rowClassName="editable-row"
                    // pagination={{
                    //     onChange: cancel,
                    // }}
                />
            </Form></>
    );
};
export default CardType;